import React, { useEffect, useState, useRef, useMemo } from "react";
import headerIcon from "../assets/headerIcon.png";
import pokeBack from "../assets/poke/pokeBack.jpg";
import ClubA from "../assets/poke/ClubA.jpg";
import Club2 from "../assets/poke/Club2.jpg";
import Club3 from "../assets/poke/Club3.jpg";
import Club4 from "../assets/poke/Club4.jpg";
import Club5 from "../assets/poke/Club5.jpg";
import Club6 from "../assets/poke/Club6.jpg";
import Club7 from "../assets/poke/Club7.jpg";
import Club8 from "../assets/poke/Club8.jpg";
import Club9 from "../assets/poke/Club9.jpg";
import ClubT from "../assets/poke/ClubT.jpg";
import ClubJ from "../assets/poke/ClubJ.jpg";
import ClubQ from "../assets/poke/ClubQ.jpg";
import ClubK from "../assets/poke/ClubK.jpg";

import DiamondA from "../assets/poke/DiamondA.jpg";
import Diamond2 from "../assets/poke/Diamond2.jpg";
import Diamond3 from "../assets/poke/Diamond3.jpg";
import Diamond4 from "../assets/poke/Diamond4.jpg";
import Diamond5 from "../assets/poke/Diamond5.jpg";
import Diamond6 from "../assets/poke/Diamond6.jpg";
import Diamond7 from "../assets/poke/Diamond7.jpg";
import Diamond8 from "../assets/poke/Diamond8.jpg";
import Diamond9 from "../assets/poke/Diamond9.jpg";
import DiamondT from "../assets/poke/DiamondT.jpg";
import DiamondJ from "../assets/poke/DiamondJ.jpg";
import DiamondQ from "../assets/poke/DiamondQ.jpg";
import DiamondK from "../assets/poke/DiamondK.jpg";

import HeartA from "../assets/poke/HeartA.jpg";
import Heart2 from "../assets/poke/Heart2.jpg";
import Heart3 from "../assets/poke/Heart3.jpg";
import Heart4 from "../assets/poke/Heart4.jpg";
import Heart5 from "../assets/poke/Heart5.jpg";
import Heart6 from "../assets/poke/Heart6.jpg";
import Heart7 from "../assets/poke/Heart7.jpg";
import Heart8 from "../assets/poke/Heart8.jpg";
import Heart9 from "../assets/poke/Heart9.jpg";
import HeartT from "../assets/poke/HeartT.jpg";
import HeartJ from "../assets/poke/HeartJ.jpg";
import HeartQ from "../assets/poke/HeartQ.jpg";
import HeartK from "../assets/poke/HeartK.jpg";

import SpadeA from "../assets/poke/SpadeA.jpg";
import Spade2 from "../assets/poke/Spade2.jpg";
import Spade3 from "../assets/poke/Spade3.jpg";
import Spade4 from "../assets/poke/Spade4.jpg";
import Spade5 from "../assets/poke/Spade5.jpg";
import Spade6 from "../assets/poke/Spade6.jpg";
import Spade7 from "../assets/poke/Spade7.jpg";
import Spade8 from "../assets/poke/Spade8.jpg";
import Spade9 from "../assets/poke/Spade9.jpg";
import SpadeT from "../assets/poke/SpadeT.jpg";
import SpadeJ from "../assets/poke/SpadeJ.jpg";
import SpadeQ from "../assets/poke/SpadeQ.jpg";
import SpadeK from "../assets/poke/SpadeK.jpg";
import "./index.less";
import { ConnectButtonCus } from "./connectButton";

const picMap = {
  ClubA: ClubA,
  Club2: Club2,
  Club3: Club3,
  Club4: Club4,
  Club5: Club5,
  Club6: Club6,
  Club7: Club7,
  Club8: Club8,
  Club9: Club9,
  ClubT: ClubT,
  ClubJ: ClubJ,
  ClubQ: ClubQ,
  ClubK: ClubK,
  DiamondA: DiamondA,
  Diamond2: Diamond2,
  Diamond3: Diamond3,
  Diamond4: Diamond4,
  Diamond5: Diamond5,
  Diamond6: Diamond6,
  Diamond7: Diamond7,
  Diamond8: Diamond8,
  Diamond9: Diamond9,
  DiamondT: DiamondT,
  DiamondJ: DiamondJ,
  DiamondQ: DiamondQ,
  DiamondK: DiamondK,
  SpadeA: SpadeA,
  Spade2: Spade2,
  Spade3: Spade3,
  Spade4: Spade4,
  Spade5: Spade5,
  Spade6: Spade6,
  Spade7: Spade7,
  Spade8: Spade8,
  Spade9: Spade9,
  SpadeT: SpadeT,
  SpadeJ: SpadeJ,
  SpadeQ: SpadeQ,
  SpadeK: SpadeK,
  HeartA: HeartA,
  Heart2: Heart2,
  Heart3: Heart3,
  Heart4: Heart4,
  Heart5: Heart5,
  Heart6: Heart6,
  Heart7: Heart7,
  Heart8: Heart8,
  Heart9: Heart9,
  HeartT: HeartT,
  HeartJ: HeartJ,
  HeartQ: HeartQ,
  HeartK: HeartK,
};

let ws;

let interval = null;

const Poker = (props) => {
  const [firstSubTitle, setFirstSubTitle] = useState("The Pre Flop");
  const [porker1, setPorker1] = useState(pokeBack);
  const [porker2, setPorker2] = useState(pokeBack);
  const [porker3, setPorker3] = useState(pokeBack);
  const [porker4, setPorker4] = useState(pokeBack);
  const [porker5, setPorker5] = useState(pokeBack);
  const [userPoke1, setUserPoke1] = useState(pokeBack);
  const [userPoke2, setUserPoke2] = useState(pokeBack);

  const [isShowAmount, setIsShowAmount] = useState(false);
  const [amountData, setAmountData] = useState(0);
  const [principalVal, setPrincipalVal] = useState(0);
  const [positionData, setPositionData] = useState("");
  const [btnText, setBtnText] = useState("call");
  const [showBtn, setShowBtn] = useState(false);
  const [showRestartBtn, setShowRestartBtn] = useState(false);
  const [content, setContent] = useState([]);
  const currentDataLen = useRef(0);
  const currentData = useRef([]);
  const currentPokerIndex = useRef(0);
  const amountDataRef = useRef(0);
  const principalValRef = useRef(0);

  useEffect(() => {
    initWs();
  }, []);

  const clickHeader = (ele) => {
    props.history.push("/home");
  };

  const initWs = () => {
    setShowRestartBtn(false);
    const url = "wss://optima-production-59c4.up.railway.app/ws/poke";
    if (!ws) {
      ws = new WebSocket(url);
      ws.onopen = (e) => {
        ws && ws.send(100);
      };

      ws.onmessage = (e) => {
        const data = JSON.parse(e.data);
        console.log(data);
        if (+data.round === 1) {
          setFirstSubTitle("Pre-flop");
        } else if (+data.round === 2) {
          setFirstSubTitle("Flop");
        } else if (+data.round === 3) {
          setFirstSubTitle("Turn");
        } else {
          setFirstSubTitle("River");
        }

        if (+data?.userMoney >= 0) {
          principalValRef.current = +data?.userMoney
        }

        if (+data?.amount >= 0) {
          amountDataRef.current = data?.amount;
        }

        if (+data.type === 1) {
          if (interval) {
            clearInterval(interval);
          }
          let index = 0;
          currentData.current[currentDataLen.current] = {
            text: "",
            type: "normal",
            id: Date.now(),
          };
          interval = setInterval(() => {
            currentData.current[currentDataLen.current].text +=
              data.message[index];
            setContent([...currentData.current]);
            setTimeout(() => {
              scrollToBottom();
            }, 10);
            if (data.message[index] === ":") {
              clearInterval(interval);
              setTimeout(() => {
                interval = setInterval(() => {
                  currentData.current[currentDataLen.current].text +=
                    data.message[index];
                  setContent([...currentData.current]);
                  setTimeout(() => {
                    scrollToBottom();
                  }, 10);
                  index++;
                  if (index === data.message.length) {
                    currentDataLen.current++;
                    if (+data?.amount >= 0) {
                      setAmountData(+data?.amount);
                      setIsShowAmount(true);
                    }
                    ws.send(101);
                    clearInterval(interval);
                    return;
                  }
                }, 50);
              }, Math.round(Math.random() * 2000 + 2000));
            }
            index++;
          }, 50);
        }

        if (+data.type === 2) {
          currentData.current[currentDataLen.current] = {
            id: `${Date.now()}1`,
            type: "title",
            text: "You",
          };
          currentDataLen.current++;
          currentData.current[currentDataLen.current] = {
            id: `${Date.now()}2`,
            type: "normal",
            text: "In a game of poker, under the conditions posted above, what decision should I make?",
          };
          setContent([...currentData.current]);
          setTimeout(() => {
            scrollToBottom();
          }, 10);
          currentDataLen.current++;
          currentData.current[currentDataLen.current] = {
            id: `${Date.now()}3`,
            type: "title",
            text: "Optima",
          };
          currentDataLen.current++;
          currentData.current[currentDataLen.current] = {
            id: `${Date.now()}4`,
            type: "normal",
            text: data.userOperation,
          };
          setContent([...currentData.current]);
          setTimeout(() => {
            scrollToBottom();
          }, 10);
          setBtnText(data.userOperation);
          setShowBtn(true);
          currentDataLen.current++;
        }

        if (+data.type === 3) {
          if (data.pokes.length === 3) {
            setPorker1(picMap[`${data.pokes[0].color}${data.pokes[0].size}`]);
            setPorker2(picMap[`${data.pokes[1].color}${data.pokes[1].size}`]);
            setPorker3(picMap[`${data.pokes[2].color}${data.pokes[2].size}`]);
            currentPokerIndex.current = 3;
          } else {
            if (currentPokerIndex.current === 3) {
              setPorker4(picMap[`${data.pokes[0].color}${data.pokes[0].size}`]);
              currentPokerIndex.current = 4;
            } else if (currentPokerIndex.current === 4) {
              setPorker5(picMap[`${data.pokes[0].color}${data.pokes[0].size}`]);
              currentPokerIndex.current = 5;
            }
          }
          if (+data?.amount >= 0) {
            setAmountData(+data?.amount);
            setIsShowAmount(true);
          }
          ws.send(101);
        }

        if (+data.type === 4) {
          if (interval) {
            clearInterval(interval);
          }
          let index = 0;
          currentData.current[currentDataLen.current] = {
            text: "",
            type: "normal",
            id: Date.now(),
          };
          interval = setInterval(() => {
            currentData.current[currentDataLen.current].text +=
              data.message[index];
            setContent([...currentData.current]);
            setTimeout(() => {
              scrollToBottom();
            }, 10);
            index++;
            if (index === data.message.length) {
              currentDataLen.current++;
              if (!data?.playerPokes.length) {
                clearInterval(interval);
                setShowRestartBtn(true);
                return;
              }
              data?.playerPokes?.forEach((item) => {
                currentData.current[currentDataLen.current] = {
                  text: item.user,
                  type: "title",
                  id: Date.now(),
                };
                currentDataLen.current++;
                currentData.current[currentDataLen.current] = {
                  pic1: picMap[`${item.pokes[0].color}${item.pokes[0].size}`],
                  pic2: picMap[`${item.pokes[1].color}${item.pokes[1].size}`],
                  type: "pic",
                  id: Date.now(),
                };
                setContent([...currentData.current]);
                setTimeout(() => {
                  scrollToBottom();
                }, 10);
                currentDataLen.current++;
              });
              if (+data?.amount >= 0) {
                setAmountData(+data?.amount);
                setIsShowAmount(true);
              }
              clearInterval(interval);
              setShowRestartBtn(true);
            }
          }, 50);
          return;
        }

        if (+data.type === 5) {
          if (interval) {
            clearInterval(interval);
          }
          let index = 0;
          currentData.current[currentDataLen.current] = {
            text: "",
            type: "normal",
            id: Date.now(),
          };
          interval = setInterval(() => {
            currentData.current[currentDataLen.current].text +=
              data.message[index];
            setContent([...currentData.current]);
            setTimeout(() => {
              scrollToBottom();
            }, 10);
            index++;
            if (index === data.message.length) {
              currentDataLen.current++;
              if (+data?.amount >= 0) {
                setAmountData(+data?.amount);
                setIsShowAmount(true);
              }
              ws.send(101);
              clearInterval(interval);
            }
          }, 50);
        }

        if (+data.type === 6) {
          if (+data?.userMoney >= 0) {
            setPrincipalVal(+data?.userMoney);
          }
          setPositionData(data.position);
          setUserPoke1(
            picMap[`${data.userPokes[0].color}${data.userPokes[0].size}`]
          );
          setUserPoke2(
            picMap[`${data.userPokes[1].color}${data.userPokes[1].size}`]
          );
          if (+data?.amount >= 0) {
            setAmountData(+data?.amount);
            setIsShowAmount(true);
          }
          ws.send(101);
          return;
        }
      };

      ws.onclose = (e) => {
        console.log("Connection closed");
      };

      ws.onerror = (e) => {
        console.log("error");
        console.log(e);
      };
    }
  };

  const scrollToBottom = () => {
    document
      .getElementsByClassName("pokerRightSecond")[0]
      .scrollTo(
        0,
        document.getElementsByClassName("pokerRightSecond")[0].scrollHeight
      );
  };

  const handleClick = () => {
    if (+amountDataRef.current >= 0) {
      setAmountData(+amountDataRef.current);
      setIsShowAmount(true);
    }
    if (+principalValRef.current >= 0) {
      setPrincipalVal(principalValRef.current);
    }

    ws.send(101);
    setTimeout(() => {
      setShowBtn(false);
    }, 200);
  };

  const handleRestartClick = () => {
    currentDataLen.current = 0;
    currentData.current = [];
    setContent([...currentData.current]);
    setPorker1(pokeBack);
    setPorker2(pokeBack);
    setPorker3(pokeBack);
    setPorker4(pokeBack);
    setPorker5(pokeBack);
    setUserPoke1(pokeBack);
    setUserPoke2(pokeBack);
    setIsShowAmount(false);
    setAmountData(0);
    setPrincipalVal(0);
    ws && ws.close();
    ws = null;
    setTimeout(() => {
      initWs();
    }, 500);
  };

  const getContent = useMemo(() => {
    return content.map((item) => {
      if (item.type === "pic") {
        return (
          <div key={`${item.id}pic`} className="otherUserPic">
            <img className="otherUserPicItem" src={item.pic1} />
            <img className="otherUserPicItem" src={item.pic2} />
          </div>
        );
      }
      return (
        <div
          key={`${item.id}text`}
          className={`${
            item.type === "title" ? "contentRowTitle" : ""
          } contentRow`}
        >
          {item.text}
        </div>
      );
    });
  }, [JSON.stringify(content)]);

  return (
    <div className="pokerMain">
      <div className="pokerLeft">
        <div className="pokerLeftTitle" onClick={() => clickHeader(0)}>
          <img className="pokerLeftTitleIcon" src={headerIcon} />
          <div className="pokerLeftTitleText">Optima</div>
        </div>

        <div className="highLightMain">Games</div>
        <div className="highLightSub">Texas Poker</div>
        <div className="normalSub">Chess</div>
        <div className="normalSub">Go</div>

        <div className="normalMain">Trading</div>
        <div className="normalSub">Generalist</div>
        <div className="normalSub">On-Chain Sniper</div>
        <div className="normalSub">Quantitative Analyst</div>
        <div className="normalSub">Scalper</div>

        <div className="normalMain">Business</div>
        <div className="normalSub">Employee</div>
        <div className="normalSub">Business Management</div>
        <div className="normalSub">Investor Relations</div>
        <div className="normalSub">Marketing</div>

        <div className="normalMain">Interpersonal</div>

        <div className="normalMain">General Optima</div>

        <div className="pokerLeftBtnContainer">
          <div className="pokerLeftBtn">
            <ConnectButtonCus type="white" />
          </div>
        </div>
      </div>
      <div className="pokerRight">
        <div className="pokerRightTitle">Texas Poker</div>
        <div className="porkerRightFirst">
          <div className="pokerRightFirstTitle">Optima</div>
          <div className="pokerRightFirstMain">
            <div>
              <div className="userPokeTitle">You({positionData})</div>
              <div className="userPokePic">
                <img className="userPokePicItem" src={userPoke1} />
                <img className="userPokePicItem" src={userPoke2} />
              </div>

              <div className="amountContainer">
                <span className="fontBold">Your Stack:</span> ${principalVal}
              </div>

              {isShowAmount ? (
                <div className="amountContainer">
                  <span className="fontBold">The Pot:</span> ${amountData}
                </div>
              ) : null}
            </div>

            <div>
              <div className="pokerRightFirstSubTitle">{firstSubTitle}</div>
              <div className="porkerRightPorker">
                <img className="porkerRightPorkerPic" src={porker1} />
                <img className="porkerRightPorkerPic" src={porker2} />
                <img className="porkerRightPorkerPic" src={porker3} />
                <img className="porkerRightPorkerPic" src={porker4} />
                <img className="porkerRightPorkerPic" src={porker5} />
              </div>
            </div>
          </div>
        </div>

        <div className="pokerRightSecond">{getContent}</div>

        {showBtn ? (
          <div className="bottomButtonContainer">
            <div className="bottomButton" onClick={handleClick}>
              {btnText}
            </div>
          </div>
        ) : null}

        {showRestartBtn ? (
          <div className="bottomButtonContainer">
            <div className="bottomButton" onClick={handleRestartClick}>
              Start New Game
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Poker;
