import { ConnectButton } from "@rainbow-me/rainbowkit";

export const ConnectButtonCus = (props) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div
                    onClick={openConnectModal}
                    className="connectBtn"
                    style={{
                      width: props.type === "blue" ? "28.7rem" : "27.2rem",
                      height: props.type === "blue" ? "4rem" : "4rem",
                      color: props.type === "blue" ? "#ffffff" : "#000000",
                      textAlign: "center",
                      paddingTop: props.type === "blue" ? "0.6rem" : "1.2rem",
                      backgroundColor:
                        props.type === "blue" ? "#0085ff" : "#ffffff",
                      borderRadius: "0.6rem",
                      cursor: "pointer",
                      fontSize: props.type === "blue" ? "2.98rem" : "2.4rem",
                      border: props.type !== "blue" ? '0.13rem solid #000000' : '',
                    }}
                  >
                    Connect Wallet
                  </div>
                );
              }
              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }
              return (
                <div
                  className={`${
                    props.type === "blue"
                      ? "connectBtnClicked"
                      : "connectBtnClickedBlack"
                  }`}
                  onClick={openAccountModal}
                >
                  <div>{account.displayName}</div>
                  <div
                    className={`${
                      props.type === "blue" ? "underline" : "underlineBlack"
                    }`}
                  />
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
