import React from "react";
import dreamPic1 from "../assets/dreamPic1.png";
import "./index.less";

const Dream = () => {
  return (
    <div className="dream">
      <div className="dreamFirst">
        <div className="dreamFirstTitle">We Power a Dream</div>
        <div className="dreamFirstSubTitle">For Anyone to Dream</div>
        <div className="dreamFirstText">
          <div>We all have our own dreams. And we also have one</div>
          <div>dream.</div>
        </div>
      </div>

      <div className="dreamSecond">
        <div className="dreamSecondType1">
          <div className="dreamSecondText1">
            <div>
              In the dawn of the digital age, the vision of Optima emerges as a
              beacon
            </div>
            <div>
              of liberation, challenging the centralized citadels that have long
            </div>
            <div>
              monopolized the realms of artificial intelligence and hardware.
              It's a vision
            </div>
            <div>
              deeply rooted in the fundamental belief that the advancement of AI
            </div>
            <div>
              should not be the exclusive dominion of towering entities, but a
              shared
            </div>
            <div>
              odyssey that beckons to every soul with a yearning for discovery
              and
            </div>
            <div className="dreamSecondText1Mb">innovation.</div>
            <div>
              Optima heralds the democratization of AI, inviting every
              individual, every
            </div>
            <div>
              device—from the simplest of smartwatches to the most complex of
            </div>
            <div>
              computing systems—to contribute their slice of computational
              power.
            </div>
            <div>
              This isn't merely about providing resources; it's about bestowing
              upon
            </div>
            <div>
              each participant the sacred right to be an integral part of the AI
            </div>
            <div className="dreamSecondText1Mb">
              revolution, to weave their threads into the grand tapestry of
              progress.
            </div>
            <div>
              By dismantling the barriers erected by centralized forces, Optima
            </div>
            <div>
              envisions a world where AI flourishes from the grassroots, where
              the
            </div>
            <div>
              power of creation and innovation is dispersed like the stars in
              the night
            </div>
            <div>
              sky—accessible to all, owned by none, and shared by humanity in a
            </div>
            <div>collective pursuit of enlightenment and advancement.</div>
            <div>
              This is not just a challenge to the status quo; it is a call to
              arms for the
            </div>
            <div>
              silent dreamers, the hidden geniuses, and the everyday
              individuals. It is a
            </div>
            <div>
              declaration that the future of AI will not be written by the few,
              but by the
            </div>
            <div>
              many. In this new era, Optima stands as both the vessel and the
              voyage,
            </div>
            <div>
              charting a course towards a horizon where technology empowers,
              rather
            </div>
            <div>
              than excludes, where every contribution molds the future, and
              where the
            </div>
            <div>AI revolution is a shared legacy of all mankind.</div>
          </div>
          <img className="dreamSecondPic1" src={dreamPic1} />
        </div>
      </div>

      <div className="dreamThird">
        <div className="dreamThirdTitle">Our Story</div>
        <div className="dreamThirdText">
          <div>
            In the sprawling tapestry of the digital age, where threads of
            innovation and ambition intertwine, the Optima Team emerged
          </div>
          <div>
            as visionaries united by a singular dream. Each of us, seasoned
            voyagers in the vast seas of Artificial Intelligence, brought a
          </div>
          <div>
            wealth of knowledge and expertise. From the intricate algorithms of
            neural networks and the predictive prowess of decision
          </div>
          <div>
            trees to the complex realms of natural language processing and
            computer vision, our journeys through the frontiers of AI
          </div>
          <div>
            had been long and arduous. We started our dreams at Google, IBM, and
            Nvidia, where we honed our skills and nurtured our
          </div>
          <div className="dreamThirdTextMb">aspirations.</div>
          <div>
            Yet, despite our expertise and shared vision, we faced daunting
            barriers: the prohibitive cost of entry into the AI market. This
          </div>
          <div>
            challenge, emblematic of the centralized monopolization that stifles
            innovation, became the crucible for our resolve. It was
          </div>
          <div className="dreamThirdTextMb">
            not just our own dreams that were being deferred, but those of
            countless others who wished to partake in the AI odyssey.
          </div>
          <div>
            Thus, we embarked on a mission to dismantle these barriers, to
            democratize access to AI development. Our endeavor was
          </div>
          <div>
            not merely about building a startup; it was about forging a beacon
            of hope and inclusivity. Optima was conceived as a
          </div>
          <div>
            bastion against the centralized powers that gatekeep the wonders of
            AI, a platform that welcomes everyone—regardless of
          </div>
          <div className="dreamThirdTextMb">
            their resources—to contribute to, and benefit from, the AI
            revolution.
          </div>
          <div>
            In this quest, Optima is more than a project; it is a declaration
            that the future of AI should not be dictated by the few, but
          </div>
          <div>
            shaped by the many. It's a dream that echoes in our hearts and
            resonates with the collective aspiration of a global
          </div>
          <div>
            community eager to participate in the advancement of AI. Together,
            we stand at the dawn of a new era, ready to redefine the
          </div>
          <div>
            landscape of technological innovation, where everyone is invited to
            contribute their verse to the ongoing story of Artificial
          </div>
          <div>Intelligence.</div>
        </div>
      </div>
    </div>
  );
};

export default Dream;
