import React, { useState, useRef } from "react";
import getAccessPic1 from "../assets/getAccessPic1.png";
import getAccessPic2 from "../assets/getAccessPic2.png";
import getAccessPic3 from "../assets/getAccessPic3.png";
import getAccessPic4 from "../assets/getAccessPic4.png";
import getAccessPic5 from "../assets/getAccessPic5.png";
import axios from "axios";

import "./index.less";

const GetAccess = () => {
  const [addressVal, setAddressVal] = useState("");
  const [valueVal, setValueVal] = useState("");
  const addressInput = useRef("");
  const valueInput = useRef("");
  const handleAddressChange = () => {
    setAddressVal(addressInput.current.value);
  };
  const handleValueChange = () => {
    setValueVal(valueInput.current.value);
  };

  const handleSubmit = () => {
    if (!addressInput.current.value || !valueInput.current.value) {
      return;
    }
    axios
      .post("https://optima-production-59c4.up.railway.app/api/user/apply", {
        walletAddress: addressInput.current.value.trim(),
        device: valueInput.current.value.trim(),
      })
      .then(function (response) {
        setAddressVal("");
        setValueVal("");
        addressInput.current.value = "";
        valueInput.current.value = "";
        console.log("请求返回的数据");
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="getAccess">
      <div className="getAccessFirst">
        <div className="getAccessFirstTitle">
          <div>Get Early Access to the</div>
          <div>Optima Network</div>
        </div>

        <div className="getAccessFirstText">
          <div>
            Join the waitlist for a chance to be among the first group of public
          </div>
          <div>
            suppliers for the network. Waitlist participants will also receive
            pre-Alpha
          </div>
          <div>launch rewards.</div>
        </div>

        <div className="getAccessFirstForm">
          <div className="getAccessFirstFormTitle1">Wallet Address</div>
          <input
            type="text"
            name="Address"
            onChange={handleAddressChange}
            ref={addressInput}
            value={addressVal}
            className="getAccessFirstFormInput"
          />
          <div className="getAccessFirstFormTitle2">
            Device for Supply (PC, Smartphone, Tablet)
          </div>
          <input
            type="text"
            name="Address"
            onChange={handleValueChange}
            ref={valueInput}
            value={valueVal}
            className="getAccessFirstFormInput"
          />
          <div className="submitBtn" onClick={handleSubmit}>
            Submit
          </div>
        </div>

        <img className="wordMap" src={getAccessPic1} />
      </div>

      <div className="getAccessSecond">
        <div className="getAccessSecondTitle">
          <div>Anywhere. Anyone.</div>
          <div>Anytime.</div>
        </div>
        <div className="getAccessSecondSubTitle">AI Without Borders</div>
        <div className="getAccessSecondText">
          <div>
            In our journey towards advancing artificial intelligence, Optima
            stands at the forefront, leading a
          </div>
          <div>
            new era of inclusivity and democratization. By harnessing
            computational resources from a wide
          </div>
          <div>
            spectrum of devices—ranging from traditional computers and
            smartphones to tablets and even
          </div>
          <div>
            FPGA systems—Optima embodies the principle that progress in AI
            should be fueled by the
          </div>
          <div>
            collective power of diverse contributors. This approach not only
            democratizes access to AI
          </div>
          <div>
            development but also ensures that the benefits of AI advancements
            are widely distributed,
          </div>
          <div className="mb3">
            promoting equal rights and opportunities within the field.
          </div>
          <div>
            Central to Optima's philosophy is a steadfast commitment to user
            empowerment. Throughout
          </div>
          <div>
            the AI inferencing process, we guarantee users absolute control and
            privacy over their data and
          </div>
          <div>
            the resultant models. By eliminating reliance on centralized
            processing for DRLs, RAGs, and
          </div>
          <div>
            CNNs, we effectively safeguard against the risks of data breaches
            and privacy intrusions. This
          </div>
          <div>
            architecture ushers in a new paradigm where concerns over data
            security are obviated, ensuring
          </div>
          <div>
            that users can trust in the integrity and confidentiality of their
            contributions.
          </div>
        </div>
      </div>

      <div className="getAccessThird">
        <div className="getAccessThirdRow">
          <div className="getAccessThirdRowItem mr75">
            <img className="getAccessThirdRowItemPic1" src={getAccessPic2} />
            <div className="getAccessThirdRowItemTitile">Privacy</div>

            <div className="getAccessThirdRowItemText">
              <div>The Optima Network provides a secure,</div>
              <div>private TEE environment at every node where</div>
              <div>tasks are distributed. This setup eliminates</div>
              <div>the risk of data and model theft, as tasks are</div>
              <div>processed in a secure environment and</div>
              <div>divided among different nodes that</div>
              <div>communicate exclusively via the</div>
              <div>decentralized network.</div>
            </div>
          </div>

          <div className="getAccessThirdRowItem">
            <img className="getAccessThirdRowItemPic2" src={getAccessPic3} />
            <div className="getAccessThirdRowItemTitile">Distributed</div>
            <div className="getAccessThirdRowItemText">
              <div>Centralized AI companies monopolizing</div>
              <div>computing hardware have caused shortages</div>
              <div>and spiked AI participation costs. The Optima</div>
              <div>Network democratizes AI advancement by</div>
              <div>leveraging edge computing devices from</div>
              <div>individuals and businesses alike,</div>
              <div>redistributing the power to innovate across</div>
              <div>the board, ensuring everyone can progress</div>
              <div>with the technological era.</div>
            </div>
          </div>
        </div>

        <div className="getAccessThirdRow">
          <div className="getAccessThirdRowItem mr75">
            <img className="getAccessThirdRowItemPic3" src={getAccessPic4} />
            <div className="getAccessThirdRowItemTitile">Edge Computing</div>
            <div className="getAccessThirdRowItemText">
              <div>The Optima Network embraces a wide range</div>
              <div>of edge computing devices—computers,</div>
              <div>laptops, tablets, phones, smartwatches, and</div>
              <div>FPGAs—making compute supply accessible to</div>
              <div>everyone. This inclusivity ensures easy.</div>
              <div>participation in distributed computing,</div>
              <div>democratizing access and fueling innovation</div>
              <div>across the technological spectrum.</div>
            </div>
          </div>

          <div className="getAccessThirdRowItem">
            <img className="getAccessThirdRowItemPic4" src={getAccessPic5} />
            <div className="getAccessThirdRowItemTitile">Authentication</div>
            <div className="getAccessThirdRowItemText">
              <div>The Optima Network employs a robust</div>
              <div>authentication and verification process for</div>
              <div>each data output across its decentralized</div>
              <div>network, ensuring sole ownership of data and</div>
              <div>models to users. This rigorous protocol</div>
              <div>ensures that every participant retains</div>
              <div>absolute control over their digital resources,</div>
              <div>reinforcing trust and security within the</div>
              <div>ecosystem.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetAccess;
