import "./App.css"
import { Route, Switch, Redirect } from 'react-router-dom'
import Main from "./components/main";
import Poker from "./components/Poker";
import "./components/font.less";
function App() {
  return (
    <Switch>
      <Route exact path="/home" component={Main}></Route>
      <Route path="/poker" component={Poker}></Route>
      <Redirect from="/" to="/home" />
    </Switch>
  )
}

export default App
