import React from "react";
import anythingPic1 from "../assets/anythingPic1.png";
import anythingPic2 from "../assets/anythingPic2.png";
import anythingPic3 from "../assets/anythingPic3.png";
import anythingPic4 from "../assets/anythingPic4.png";
import anythingPic5 from "../assets/anythingPic5.png";
import "./index.less";

const OptimaAnything = () => {
  return (
    <div className="optimaAnything">
      <div className="optimaAnythingFirst">
        <div className="optimaAnythingFirstTitle">Optimize Anything</div>
        <div className="optimaAnythingFirstSubTitle">Like a Game of GO</div>
        <div className="optimaAnythingFirstText">
          <div>Discover the possibilities beyond the game boards.</div>
          <div>Delve into the endless possibilities that Optima</div>
          <div>unlocks, transcending traditional boundaries to</div>
          <div>redefine decision-making.</div>
        </div>
      </div>

      <div className="optimaAnythingSecond">
        <div className="optimaAnythingSecondType1">
          <img className="optimaAnythingSecondPic1" src={anythingPic1} />
          <div className="optimaAnythingSecondText1">
            <div className="optimaAnythingSecondTitle mt6">Games</div>
            <div>Optima revolutionizes strategy and decision-making</div>
            <div>in games like poker, chess, and Go. Whether it's</div>
            <div>outmaneuvering opponents in the complex</div>
            <div>battlefields of chess, calculating the odds in the</div>
            <div>dynamic landscapes of poker, or navigating the</div>
            <div>profound simplicity of Go, Optima equips players</div>
            <div>with the foresight and precision of a seasoned</div>
            <div>grandmaster. Experience the future of gaming, where</div>
            <div>every move is optimized, and every decision is a step</div>
            <div>towards victory with Optima.</div>
          </div>
        </div>

        <div className="optimaAnythingSecondType1 mt20">
          <div className="optimaAnythingSecondText1">
            <div className="optimaAnythingSecondTitle mt4">Trading</div>
            <div>Optima redefines trading by serving as an on-chain</div>
            <div>sniper, targeting high-potential early blockchain</div>
            <div>tokens, a versatile generalist, a meticulous</div>
            <div>quantitative analyst, and an agile scalper. It navigates</div>
            <div>the market with precision, identifying lucrative</div>
            <div>opportunities and executing trades at the pinnacle of</div>
            <div>decision-making efficiency. Whether optimizing</div>
            <div>portfolios, analyzing market data for predictive</div>
            <div>insights, or executing rapid-fire trades, Optima</div>
            <div>ensures every action maximizes expected returns.</div>
            <div>Step into a world where trading is not just about</div>
            <div>profits, but about achieving optimal outcomes with</div>
            <div>unparalleled accuracy.</div>
          </div>
          <img className="optimaAnythingSecondPic1" src={anythingPic2} />
        </div>

        <div className="optimaAnythingSecondType1 mt20">
          <img className="optimaAnythingSecondPic1" src={anythingPic3} />
          <div className="optimaAnythingSecondText1">
            <div className="optimaAnythingSecondTitle mt2">Business</div>
            <div>Optima transforms business decision-making by</div>
            <div>adopting the roles of an insightful employee, a</div>
            <div>strategic manager, an investor relations expert, and a</div>
            <div>marketing visionary. Its ability to analyze and</div>
            <div>interpret complex scenarios enables businesses to</div>
            <div>navigate challenges and seize opportunities with</div>
            <div>unmatched precision. From optimizing internal</div>
            <div>processes and enhancing employee productivity to</div>
            <div>forging strong investor relationships and crafting</div>
            <div>compelling marketing strategies, Optima ensures</div>
            <div>every decision aligns with the company's overarching</div>
            <div>goals. Step into an era where strategic business</div>
            <div>decisions are not just made—they're optimized for</div>
            <div>success.</div>
          </div>
        </div>

        <div className="optimaAnythingSecondType1 mt20">
          <div className="optimaAnythingSecondText1">
            <div className="optimaAnythingSecondTitle mt5">Interpersonal</div>
            <div>Optima revolutionizes interpersonal relations by</div>
            <div>acting as a digital mediator, offering tailored</div>
            <div>suggestions for every scenario. Trained as an agent</div>
            <div>with deep social understanding, it navigates the</div>
            <div>nuances of human interaction, fostering harmony</div>
            <div>and understanding. Whether resolving conflicts,</div>
            <div>enhancing communication, or strengthening bonds,</div>
            <div>Optima provides insights that transcend</div>
            <div>conventional wisdom. Its unparalleled ability to</div>
            <div>optimize social dynamics turns every interaction into</div>
            <div>an opportunity for growth and deeper connection.</div>
          </div>
          <img className="optimaAnythingSecondPic1" src={anythingPic4} />
        </div>

        <div className="optimaAnythingSecondType1 mt20">
          <img className="optimaAnythingSecondPic1" src={anythingPic5} />
          <div className="optimaAnythingSecondText1">
            <div className="optimaAnythingSecondTitle mt6">General Optima</div>
            <div>As it evolves, Optima aims to transcend its current</div>
            <div>capabilities, aspiring to become the ultimate</div>
            <div>universal optimizer. Its mission is to master the art of</div>
            <div>optimizing decision-making processes across an</div>
            <div>unlimited spectrum, far beyond games, trading,</div>
            <div>business, and interpersonal relations. Through</div>
            <div>continuous development, Optima seeks to unlock the</div>
            <div>full potential of an AGI, offering unparalleled decision</div>
            <div>support in every conceivable scenario, redefining</div>
            <div>what's possible with technology.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimaAnything;
