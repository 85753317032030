import React from "react";
import stagePic1 from "../assets/stagePic1.png";
import stagePic2 from "../assets/stagePic2.png";
import "./index.less";

const EarlyStage = () => {
  return (
    <div className="earlyStage">
      <div className="earlyStageFirst">
        <div className="earlyStageFirstTitle1">Early Stage</div>
        <div className="earlyStageFirstTitle2">Rewards Quests</div>
      </div>

      <div className="earlyStageSecond">
        <div className="earlyStageSecondLeft">
          <div className="earlyStageSecondLeftTitle">Quest 1</div>
          <div className="earlyStageSecondLeftSubTitle">Optimize and Queue</div>
          <div className="earlyStageSecondLeftText">
            <div>Early users of Optima Beta who also sign</div>
            <div>up for the waitlist for compute supply</div>
            <div className="mb3">stand to receive future rewards.</div>
            <div>To ensure eligibility for these rewards,</div>
            <div>users must use the same wallet address</div>
            <div>for both experiencing Optima Beta and</div>
            <div>filling out the waitlist form.</div>
          </div>
        </div>
        <img className="earlyStageSecondRightPic" src={stagePic1} />
      </div>

      <div className="earlyStageThird">
        <img className="earlyStageThirdLeftPic" src={stagePic2} />
        <div className="earlyStageThirdRight">
          <div className="earlyStageThirdRightTitle">Quest 2</div>
          <div className="earlyStageThirdRightSubTitle">Coming Soon</div>
        </div>
      </div>
    </div>
  );
};

export default EarlyStage;
