import React, { useState } from "react";
import headerIcon from "../assets/headerIcon.png";
import { Dropdown } from "antd";
import downArrow from "../assets/downArrow.png";
import birdIcon from "../assets/birdIcon.png";
import Home from "./home";
import OptimaAnything from "./optimaAnything";
import mobilePic from "../assets/mobilePic.png";
import GetAccess from "./getAccess";
import Dream from "./dream";
import EarlyStage from "./earlyStage";
import "./index.less";
import { ConnectButtonCus } from "./connectButton";
import { useAccount } from "wagmi";

const Main = (props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const { address, isConnected } = useAccount();
  console.log(address, "address", isConnected, 'isConnected"');

  const items = [
    {
      label: (
        <div className="headerBtnOptText" onClick={() => clickHeader(5)}>
          About Us
        </div>
      ),
      key: "0",
    },
    {
      disabled: true,
      label: <div className="headerBtnOptText">Whitepaper</div>,
      key: "1",
    },
    {
      disabled: true,
      label: <div className="headerBtnOptText">Roadmap</div>,
      key: "2",
    },
  ];

  const clickHeader = (ele) => {
    scrollToTop();
    if (ele === 1) {
      window.open(`${window.location.origin}/#/poker`);
      return;
    }
    setPageIndex(ele);
  };

  const setMainPageIndex = (index) => {
    scrollToTop();
    setPageIndex(index);
  };

  const scrollToTop = () => {
    setTimeout(() => {
      if (
        document.getElementById("root") &&
        document.getElementById("root").parentElement &&
        document.getElementById("root").parentElement.parentElement
      ) {
        document.getElementById(
          "root"
        ).parentElement.parentElement.scrollTop = 0;
      }
    }, 50);
  };

  return (
    <>
      {navigator.userAgent.includes("Windows") ? (
        <div className="main">
          <div className="mainHeader">
            <div className="mainHeaderLeft" onClick={() => clickHeader(0)}>
              <img className="mainHeaderLeftIcon" src={headerIcon} />
              <div className="mainHeaderLeftText">Optima</div>
            </div>
            <div className="mainHeaderMiddle">
              <div className="middleBtn" onClick={() => clickHeader(1)}>
                Optima Beta
              </div>
              <div className="middleBtn" onClick={() => clickHeader(2)}>
                Rewards Quests
              </div>
              <div className="middleBtn" onClick={() => clickHeader(3)}>
                Use
              </div>
              <div className="middleBtn" onClick={() => clickHeader(4)}>
                Nodes
              </div>

              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <div className="middleBtn">
                  About Us
                  <img className="middleBtnIcon" src={downArrow} />
                </div>
              </Dropdown>
            </div>
            <div className="mainHeaderRight">
              <img className="mainHeaderRightIcon2" src={birdIcon} onClick={() => {window.open('https://twitter.com/OptimaAI')}}/>
              <ConnectButtonCus type="blue" />
            </div>
          </div>

          <div className="mainContainer">
            {pageIndex === 0 ? (
              <Home setMainPageIndex={setMainPageIndex} />
            ) : null}
            {pageIndex === 2 ? <EarlyStage /> : null}
            {pageIndex === 3 ? <OptimaAnything /> : null}
            {pageIndex === 4 ? <GetAccess /> : null}
            {pageIndex === 5 ? <Dream /> : null}

            <div className="mainFooter">
              <div className="footerText">
                Join The Waitlist to become a Supplier
              </div>
              <div className="footerBtn" onClick={() => clickHeader(4)}>
                Become a Node
              </div>
              <div className="footerBottom">
                <div
                  className="footerBottomLeft"
                  onClick={() => clickHeader(0)}
                >
                  <img className="footerBottomLeftIcon" src={headerIcon} />
                  <div className="footerBottomLeftText">Optima</div>
                </div>
                <div className="footerBottomMiddle">All Rights Reserved.</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img className="forMobilePic" src={mobilePic} />
      )}
    </>
  );
};

export default Main;
